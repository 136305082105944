<template>
  <div class="root-div mx-auto">
    <div class="py-10 mx-auto">
      <v-card class="login-div text-center" v-if="myInfo && myInfo.hasOwnProperty('totalPoints')">
        <img :src="assets.star" :height="50" />
        <p class="my-3 font-bold font-20 font-pink">{{ getDecimalFormat(myInfo.totalPoints) }} / {{ getDecimalFormat(myInfo.usedPoints) }} pts</p>
        <b-progress
          class="m-5"
          :value="myInfo.totalPoints - myInfo.usedPoints"
          :max="myInfo.totalPoints"
          variant="danger"
          animated
        />
      </v-card>
      <!--begin::Form-->
      <v-card class="my-5 login-div">
        <p class="h2 my-5 font-bold font-pink">Edit your information</p>
        <b-form class="form" @submit.stop.prevent="onSubmit">
          <b-form-group label="Email">
            <b-form-input
              class="form-control form-control-solid p-2"
              v-model="myInfo.email"
              disabled
            />
          </b-form-group>
          <b-form-group label="First Name">
            <b-form-input
              class="form-control form-control-solid p-2"
              v-model="$v.form.firstName.$model"
              :state="validateState('firstName')"
              aria-describedby="feedback_first_name"
            />
            <b-form-invalid-feedback id="feedback_first_name">
              First Name is required.
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Last Name">
            <b-form-input
              class="form-control form-control-solid p-2"
              v-model="$v.form.lastName.$model"
              :state="validateState('lastName')"
              aria-describedby="feedback_last_name"
            />
            <b-form-invalid-feedback id="feedback_last_name">
              Last Name is required.
            </b-form-invalid-feedback>
          </b-form-group>
          <span class="m-1">Address</span>
          <GmapAutocomplete 
            ref="autocompleteRef"
            class="mt-1 form-control form-control-solid p-2"
            placeholder=""
            @change="onChanged"
            @place_changed="onPlaceChanged"
            />
          <b-form-group class="mt-4" label="Phone Number">
            <b-form-input
              class="form-control form-control-solid p-2"
              v-model="$v.form.phone.$model"
              :state="validateState('phone')"
              aria-describedby="feedback_phone"
            />
            <b-form-invalid-feedback id="feedback_phone">
              Phone Number is required and must be valid phone number.
            </b-form-invalid-feedback>
          </b-form-group>

          <!--begin::Action-->
          <v-btn block large class="mt-5 text-capitalize" color="#EB4C60" @click="onSubmit">
            <span class="font-bold text-white font-15">Save</span>
          </v-btn>
          <!--end::Action-->
        </b-form>
        <!--end::Form-->
      </v-card>
    </div>
    <!-- Footer -->
    <KTFooterExtended></KTFooterExtended>
  </div>
</template>

<style lang="scss" scoped>
  @import "@/assets/sass/pages/auth.scss";
</style>

<script>
import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";
import { functions } from '../../main';
import { getDecimalFormat, showLoading } from '../../functions';
import { httpsCallable } from 'firebase/functions';
import KTFooterExtended from "@/view/layout/footer/FooterExtended.vue";

import star from '@/assets/image/star.png';

export default {
  mixins: [validationMixin],
  name: "Profile",
  components: {
    KTFooterExtended
  },
  computed: {
    myInfo() {
      return this.$store.state.myInfo;
    }
  },
  watch: {
    myInfo() {
      this.setMyInfo();
    }
  },
  data() {
    return {
      assets: {
        star
      },
      form: {
        firstName: '',
        lastName: '',
        phone: ''
      },
      geoPoint: null,
      address: ''
    };
  },
  validations: {
    form: {
      firstName: {
        required
      },
      lastName: {
        required
      },
      phone: {
        required,
        minLength: minLength(10)
      },
    }
  },
  mounted() {
    if (this.myInfo) {
      this.setMyInfo();
    } else {
      this.$router.push('/');
    }
  },
  methods: {
    getDecimalFormat,
    setMyInfo() {
      if (this.myInfo) {
        this.form.firstName = this.myInfo.firstName;
        this.form.lastName = this.myInfo.lastName;
        this.form.phone = this.myInfo.phone;
        this.geoPoint = this.myInfo.geoPoint;
        this.address = this.myInfo.address;
        this.$refs.autocompleteRef.$el.value = this.address;
      }
    },
    onChanged(event) {
      this.$refs.autocompleteRef.$el.value = this.address;
    },
    onPlaceChanged(placeData) {
      if (placeData === null || placeData.geometry === null || placeData.geometry.location === null || placeData.formatted_address === null) {
        return;
      }
      const location = JSON.parse(JSON.stringify(placeData.geometry.location));
      this.geoPoint = { latitude: location.lat, longitude: location.lng };
      this.address = placeData.formatted_address;
      this.$refs.autocompleteRef.$el.value = this.address;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      if (this.myInfo === null) {
        this.$toast.error('Please login first!');
        this.$router.push('/');
        return;
      }
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      if (this.address === '' || this.geoPoint === null) {
        this.$toast.error('Please enter your address.');
        return;
      }
      const firstName = this.$v.form.firstName.$model;
      const lastName = this.$v.form.lastName.$model;
      const phone = this.$v.form.phone.$model;

      const params = {
        userId: this.myInfo.userId,
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        address: this.address,
        geoPoint: this.geoPoint,
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, 'updateUser');
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        const userInfo = JSON.parse(response.data);
        if (userInfo) {
          this.$toast.success('Your profile is updated successfully.');
        } else {
          this.$toast.error('Unexpected error occurs!');
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>